.actu-list, .page-recrutement {
  //padding-top: 5rem;

  .container {
    max-width: 1440px;
  }

  .filter {
    margin-bottom: 3rem;

    p {
      color: #3CAECB;
      font-weight: bold;
      position: fixed;
    }

    ul {
      padding: 0;
      list-style: none;
      color: #9C9E9F;
      position: fixed;
      margin-top: 2.5rem;
      a {
        color: #9C9E9F;
      }

      li.active {
        color: #87888A;
        font-weight: bold;
        a {
          color: #87888A;
        }
      }
    }
  }

  article {
    padding-bottom: 10rem;
  }

  h2 {
    font-size: 2.71rem;
  }

  .date {
    color: #285771;
    font-weight: bold;
  }

  .chapeau {
    color: #706F6F;
    font-size: 1.25rem;
  }

  .plus {
    font-weight: bold;
    font-size: 1.2rem;
    text-decoration: underline;
  }

  .article-content {
    max-width: 47rem;
  }
}

.actu-list {
  img {/*
    height: 100%;
    object-fit: cover;*/
  }
}

@include media-breakpoint-up(lg) {
  .actu-list {

    article:nth-of-type(2n) {
      .col-img {
        order: 3;
      }
      .col-null {
        order: 2;
      }
    }
    .chapeau {
      column-count: 2;
    }
  }
}

@include media-breakpoint-up(xl) {
  .actu-list {
    .chapeau {
      margin-bottom: 5rem;
    }
  }
}

@media screen and(max-width: 750px) {
  .actu-list {

    article {
      padding-bottom: 5rem;
    }

    .chapeau {
      column-count: initial;
      margin-bottom: 2rem;
    }

    article .col-md-4, article:nth-of-type(2n) .col-md-4 {
      order: initial;
      margin-bottom: 1rem;
    }
  }
}

.tags {
  margin-bottom: 3rem;
}

.tags a {
  color: #9C9E9F;
  text-decoration: underline;
}

.actus-liees {
  h2 {
    font-size: 1.4rem;
    margin-bottom: 2rem;
    font-weight: bold;
    color: #285771;
  }

  .liees-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr));
    grid-gap: 10rem;
  }

  article {
    padding: 0;
    background: url("https://i.pinimg.com/originals/dd/8c/03/dd8c03a7536f8197ba4d0f589caca97c.jpg") no-repeat top center;
    background-size: cover;
    height: 30em;
    box-sizing: border-box;
    cursor: pointer;
    overflow: hidden;
  }

  .fond {
    background: rgba(0, 0, 0, 0.75);
    box-sizing: border-box;
    height: 100%;
    padding: 1px;
    transition: .35s ease-in-out;
  }

  .cadre {
    height: 28em;
    display: flex;
    padding: 4rem;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: #ffffff;
    margin: 1rem;
    border: 1px solid transparent;
    border-image: linear-gradient(0deg, rgba(255, 255, 255, 1) 42%, rgba(85, 85, 85, 0) 42%, rgba(78, 78, 78, 0) 58%, rgba(255, 255, 255, 1) 58%) 1;
    position: relative;
    transition: .3s ease-in-out;
  }

  .cadre:before {
    content: "";
    height: 1px;
    width: 2.2rem;
    position: absolute;
    top: 50%;
    background: #FFF;
    left: -1.2rem;
    transition: .3s ease-in-out;
  }

  .cadre::after {
    content: "";
    height: 1px;
    width: 2.2rem;
    background: #FFF;
    position: absolute;
    top: 50%;
    right: -1.2rem;
    transition: .3s ease-in-out;
  }

  h3 {
    line-height: 1.5;
    transition: .3s ease-in-out;
  }

  article:hover {
    .cadre {
      transform: scale(1.15);
    }

    .cadre:before {
      transform: translateX(-3rem);
    }

    .cadre:after {
      transform: translateX(3rem);
    }

    .fond {
      background: rgba(0, 0, 0, 0.3);
    }
  }
}

.single-actu {
  article {
    .date {
      font-size: 1.2rem;
      color: #285771;
      padding-bottom: 0;
      font-weight: bold;
    }
    h1 {
      font-size: $s36px;
      color: #285771;
      padding-bottom: 1rem;
      font-weight: bold;
    }

    h2, h3 {
      color: #285771;
      margin-bottom: 1rem;
      font-family: $font-normal;
      font-size: 2rem;
    }

    article p {
      font-size: 1.25rem;
      color: #706F6F;
    }

    .navigation {
      margin-top: 5rem;

      a:first-child {
        margin-right: 1rem;
      }

      a:nth-child(2) {
        margin-left: 1rem;
      }
    }
    .video {
      padding-top: 56.25%;
      position: relative;
      iframe {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;
      }
    }
  }
}

.art-img {
  img {
    display: block;
  }
  span {
    font-size: 0.9rem;
    color: $text-grey;
  }
}

.primary-link, .secondary-link {
  line-height: 1;
  font-size: 1.2rem;
  font-weight: bold;
}

.primary-link {
  color: #ffffff;
  padding: .5em 1em;
  background-color: #285771;
}

.primary-link:hover {
  color: #ffffff;
  background-color: #336f90;
  text-decoration: none;
}

.secondary-link {
  color: #285771;
  text-decoration: underline;
}

.pagination {
  li {

    a {
      display: inline-block;
      padding: 0.2rem 0.5rem;
      font-family: $font-bold;
    }
  }
}