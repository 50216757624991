.page-qsn {
  .qsn-pagetitle {
    color: #FFF;
  }
  section {
    font-size: $s16px;
    .section-title {
      display: block;
      padding-top: 4rem;
      margin-left: -15px;
      margin-right: -15px;
      z-index: 3;
      padding-left: 8.33333%;
      span {
        padding-left: 15px;
        padding-right: 15px;
        background: #FFF;
      }
    }
    h1.logo {
      background: transparent url("../img/logo_primary.svg") left center no-repeat;
      background-size: contain;
      min-height: 4rem;
      margin: 0;
    }

    .chapeau {
      font-family: $font-bold;
      font-size: $s36px;
      color: $primary;
      padding-bottom: 1em;
    }
    .intro {
      color: $primary;
      font-size: $s18px;
      padding-bottom: 3rem;
    }

    &.opening {
      margin-top: -3rem;
      //background: #020113 url("../img/bg/innovation1920-20.png") right bottom no-repeat;
      background: #13324e url("../img/bg/Gamain-quiSN.jpg") right bottom no-repeat;
      background-size: auto 750px;
      .section-title {
        color: #FFF;
        span {
          background: #13324e;
        }
        .line:after {
          background: #FFF;
        }
      }
      .part1 {
        min-height: calc(100vh - 12rem);
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        * {
          color: #FFF;
        }
      }
    }
    .part2 {
      padding-top: 4rem;
      min-height: calc(100vh - 3rem);
      & > div {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
      }
    }
    &.valeurs {
      font-size: $s18px;
    }
    &.certifications {
      .chapeau {
        font-size: $s18px;
        font-family: $font-normal;
      }
      .iso {
        font-family: $font-bold;
        color: $primary;
      }
      .iso-desc {
        color: $text-lightgrey;
      }
      .iso-logo {
        img {
          display: inline-block;
          max-width: 70px;
          height: auto;
        }
      }
    }
    .section1-image {
      height: calc(100% - 10rem);
      background: {
        color: transparent;
        position: center center;
        size: cover;
        repeat: no-repeat;
      }
    }
  }
  #img-large {
    div#il-bg {
      background-image: url('../img/QSN_03.jpg');
      background-position: center center;
    }
  }
  #img-large-before {
    & > .container {
      min-height: calc(100vh - 22rem);
    }
  }

  section.fh.dark {
    h2 {
      color: #FFF;
      font-size: $s32px;
    }
    h3 {
      color: $dark-title;
      font-size: $s36px;
      font-family: $font-bold;
    }
    .slick-dots {
      justify-content: center;
    }
    &.infra {
      .img {
        //padding-top: 10rem;
      }
      & > .row {
        align-items: center;
        min-height: calc(100vh - 7rem);
      }
    }
  }
  #val-slider {
    .slick-arrow {
      position: absolute;
      top: calc(50% - 3.8rem);
      left: 0;
      right: auto;
      z-index: 3;
      background-position: center;
      padding: 3rem;

      -webkit-tap-highlight-color: rgba(255, 255, 255, 0) !important;
      -webkit-focus-ring-color: rgba(255, 255, 255, 0) !important;
      outline: none !important;

      &.slick-next {
        left: auto;
        right: 0;
      }
    }
    .sub-fh {
      height: calc(100vh - 26rem);
      min-height: 30rem;
      display: flex;
    }
    .col-12 {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
}

.histoire-p2 {
  columns: 2;
  color: $primary;
  font-size: $s16px;
  //padding-bottom: calc(100vh - 60rem);
  & + p.chapeau {
    padding: 6rem 0;
  }
}