@media (max-width: 1250px) {
  header {
    .navbar-nav {
      .nav-item {
        .nav-link {
          padding-left: 0;
          padding-right: 0;
        }
      }
    }
    form {
      input[type="search"] {
        width: 8rem;
      }
    }
  }
  body.scrolled {
    header {
      .navbar-nav {
        .nav-item {
          .nav-link {
            padding-left: 0;
            padding-right: 0;
          }
        }
      }
    }
  }
}

/* disable img effect */
@media (max-width: 999px) {
  #img-large div#il-bg {
    width: 100%;
    height: 66vw;
    top: 0;
    left: 0;
  }
}

@include media-breakpoint-down(lg) {
  .pagesep {
    display: none;
  }
  .contact {
    form[name="contact"] {
      max-width: unset;
    }
  }

  .product-list-page {
    min-height: calc(100vh - 25rem);
  }
  .actu-list .article-content, .page-recrutement .article-content {
    max-width: 56rem;
  }
}

@include media-breakpoint-down(md) {

  .product-list-page {
    h1 {
      font-size:$s26px;
    }
    .page-desc {
      font-family: $font-normal;
      font-size: $s17px;
    }
  }

  .m-hide {
    display: none;
  }
  header {
    .navbar-dsk {
      display: none;
    }
  }
  .mobilemenu {
    display: block;
    position: fixed;
    left: 0;
    right: 0;
    top: 5rem;
    bottom: 0;
    z-index: 99;
    background: $primary;
    transform: translate(-100vw);
    transition: transform 0.2s;
    &.open {
      transform: translate(0);
    }
  }
  body.menu-open {
    height: 100vh;
    overflow: hidden;
  }
  section.fh {
    min-height: unset;
  }
  #img-large {
    min-height: 66vw;
    div#il-content {
      font-size: 3vw;
      background-size: auto 15vw;
      padding: 20vw 20vw 0;
    }
  }
  #img-large-before > .container {
    min-height: unset;
    padding-top: 2rem;
  }
  .ariane {
    width: 100vw;
    padding: 0 0 1em 0;
    .col {
      overflow: hidden;
      overflow-x: scroll;
      width: auto;
      white-space: nowrap;
      padding-left: 0;
      padding-right: 0;
      .d-block {
        a {
          display: inline-block;
          padding: 0.5rem 1rem;
          border: 1px solid $primary;
          border-radius: 3px;
          &:last-child {
            margin-right: 15px;
          }
        }
      }
      .d-none {
      }
      & > div {
        padding: 0 15px;
      }
    }
  }
  .pl-back {
    margin-bottom: 0;
  }
  #homeCarousel {
    .slick-dots {
      top: 3rem;
      left: 0;
      li {
        height: 2rem;
        padding: 0.7rem 0.4rem;
        button {
          width: 0.8rem;
          height: 0.8rem;
        }
      }
    }
    .slick-arrow {
      display: none !important;
    }
    .slide {
      .caption {
        font-size: 1.3rem;
        &.first h1, h2 {
          font-size: 2.6rem;
        }
        &.first h2, h3 {
          font-size: 2rem;
          margin: 1.4rem 0;
        }
      }
    }
  }
  .respsoc {
    img {
      width: 100%;
    }
  }

  .histoire-p2 {
    columns: 1;
    padding-bottom: 0;
  }

  .page-qsn {
    section {
      &.opening {
        background-image: none;
        background-color: #285771;
        .part1 {
          min-height: unset;
        }
        .part2 {
          padding-top: 0;
          min-height: unset;
        }
        .section-title span {
          background-color: #285771;
        }
      }
      .intro {
        padding-bottom: 0;
      }
      .chapeau {
        &.pt-0 {
          font-size: $s26px;
        }
      }
      .section1-image {
        height: 40vh;
      }
      &.fh.dark.infra {
        & > .row {
          min-height: unset;
        }
      }
      .section-title {
        padding-left: 0;
      }
      &.certifications {
        .iso-logo {
          img {
            max-width: 100%;
          }
        }
      }
    }
    #val-slider {
      .sub-fh {
        height: auto;
      }
    }
    #infra-2 {
      padding-bottom: 15px;
    }
  }

  .product-page {
    .description {
      a.btn {
        display: block;
        //font-size: $s19px;
        margin: 0;
      }
    }
  }
  .btn-inv {
    .eye-icon {
      margin-right: 0.5em;
    }
  }
  .actu-list {

  }
  .single-actu {
    .date {
      padding-bottom: 0;
    }
  }
  // Réduction tailles

}

@include media-breakpoint-down(sm) {
  #homeCarousel {
    .slide {
      .caption {
        font-size: $s16px;
        &.first h1, h2 {
          font-size: $s26px;
        }
        &.first h2, h3 {
          font-size: $s22px;
          margin: 1rem 0;
        }
        a {
          margin-top: 0.6rem;
        }
      }
    }
  }

  #img-large {
    div#il-content {
      font-size: 4vw;
    }
  }
  .page-qsn {
    section {
      &.opening {
        .chapeau {
          font-size: $s22px;
        }
      }
    }
  }
  .product-page {
    .description {
      a.btn {
        margin: 0 10vw;
      }
    }
  }
  section.product-list {
    .desc {
      min-height: 0;
    }
  }
  .ariane {
    .col {
      .d-none {
        //padding-bottom: 0;
      }
    }
  }
}

/** special PETITS mobiles < 375 */
@media (max-width: 374px) {
  .mobilemenu {
    font-size: $s18px;
    .translate {
      .block {
        li {
          line-height: 1.6rem;
          a{
            padding-top: 0.4rem;
            padding-bottom: 0.3rem;
          }
        }
      }
    }
     & > .container-fluid {
       .row.m-bottom-row {
         font-size: 1rem;
       }
       .row.m-search-row {
         padding-bottom: 0 !important;
         margin-bottom: 0 !important;
       }
     }
  }
}