html, body {
  font-size: 14px;
}

body {
  overflow-y: scroll;
  font-family: $font-normal;
  padding-top: 1px;
  &.scrolled {
    header + * {
      margin-top: 3rem;
    }
    border-bottom: 2rem solid $footer-bg;
  }
  header + * {
    margin-top: 5rem;
    transition: margin-top 0.2s;
  }
}

span.blue {
  color: $primary;
}

span.grey {
  color: $text-grey;
}

strong {
  font-family: $font-bold;
  font-weight: normal;
}

.larger {
  font-size: 130%;
  padding-top: 0.2rem;
}

.container-fluid {
  & > .container {
    padding: {
      left: 0;
      right: 0;
    }
  }
}

.nav-main {
  padding: 0 1rem;
  position: relative;
  background: #FFF;
  z-index: 11;
  .navbar-brand {
    background: transparent url('img/logo.svg') left center no-repeat;
    background-size: contain;
    height: 3rem;
    margin: 1rem 0;
    min-width: 249px;
    width: 16.666%;
    transition: margin 0.2s, height 0.2s;
  }
}

a.external {
  display: inline-block;
  padding-left: 1.2em;
  background: transparent url('img/external.svg') left center no-repeat;
  background-size: 0.8em;
  &.white {
    background-image: url('img/external_white.svg');
  }
}

$menutransition: 0.2s;
header {
  position: fixed;
  z-index: 5;
  left: 0;
  right: 0;
  top: 0;
  font-size: $s16px;
  border-bottom: 1px solid #C6C7C8;
  .navbar-dsk {
    display: flex;
    flex-basis: 100%;
    flex-grow: 1;
    align-items: center;
  }
  .navbar-nav {
    .nav-item {
      padding: 0 1rem;
      background: transparent url("img/arrow_up.svg") center bottom no-repeat;
      background-size: auto 12px;
      background-position: center 5rem;
      transition: background-position $menutransition;
      .nav-link {
        height: 5rem;
        padding: 1.5rem;
        line-height: 2rem;
        vertical-align: middle;
        transition: height 0.2s, padding 0.2s, line-height 0.2s;
      }

      .active {
        font-family: $font-bold;
      }

      &.open {
        background-position: center bottom;
      }
    }
  }

  .dropdown {
    min-width: 8.3333%;
    text-align: right;
  }
}

header, .mobilemenu {
  form {
    border-bottom: 1px solid black;

    input, button {
      border: none;
      background: none;
      display: inline-block;
      vertical-align: middle;
    }

    button {
      width: 1.3rem;
      height: 1.3rem;
      background: transparent url('img/search.svg') center center no-repeat;
      background-size: contain;
    }
  }
}

.mobilemenu {
  font-size: 1.5rem;
  line-height: 2.7rem;
  display: none;
  transition: top 0.2s;
  a {
    color: #FFF;
  }
  .arrowback {
    background: transparent url('./img/white_arrow.svg') center center no-repeat;
    transform: rotate(180deg);
    display: inline-block;
    width: 2rem;
    height: 2.4rem;
    vertical-align: bottom;
  }
  .blocknav {
    font-size: 1rem;
    & + * {
      clear: both;
    }
    span.title {
      display: inline-block;
      color: $text-light;
    }
  }
  form {
    border-bottom: 1px solid white;
    input, button {
      color: white;
      font-family: $font-bold;
    }
    input {
      width: calc(100% - 3em);
    }
    button {
      filter: invert(1);
      width: 2em;
      height: 2em;
      background-size: 1.5em;
    }
  }
  & > .container-fluid {
    display: flex;
    flex-direction: column;
    height: 100%;
    color: #FFF;
    padding: {
      left: $grid-gutter-width;
      right: $grid-gutter-width;
    }
    & > .row {
      flex-grow: 0;
    }
    .row.m-search-row {
      @extend .py-3;
    }
    .row.m-mainmenu-row {
      flex-grow: 1;
      display: block;
      overflow: hidden;
      margin: {
        left: - $grid-gutter-width;
        right: - $grid-gutter-width;
      }
      @extend .py-3;
    }
    .row.m-bottom-row {
      font-size: 1.3rem;
      line-height: 2rem;
      a {
        color: $footer-link;
      }
    }
  }
  .translate {
    display: flex;
    width: 600vw;
    transition: transform 0.2s;
    height: 100%;
    .block {
      padding: {
        right: $grid-gutter-width;
        left: $grid-gutter-width;
      }
      width: 100vw;
      height: 100%;
      overflow: visible;
      overflow-y: scroll;
      -webkit-overflow-scrolling: touch;
      box-sizing: border-box;
      &.row {
        min-height: calc(100% + 1px);
      }

      li {
        display: flex;
        line-height: 2rem;
        margin: {
          left: - $grid-gutter-width / 2;
          right: - $grid-gutter-width / 2;
        }
        padding: {
          left: $grid-gutter-width / 2;
          right: $grid-gutter-width / 2;
        }
        @extend .my-3;
        &.active, &:hover {
          background: darken($primary, 10%);
        }
        a {
          padding-top: 0.7rem;
          padding-bottom: 0.5rem;
          &:first-child {
            flex-basis: calc(100% - 3rem);
          }
          &:hover {
            text-decoration: none;
          }
          &.deeper {
            width: 3rem;
            flex-basis: 3rem;
            margin-left: 2rem;
            text-align: center;
            float: right;
          }
        }

        &.locales-menuitem {
          border-bottom: 1px solid #FFF;
          margin: {
            left: 0;
            right: 0;
            bottom: 1em !important;
          }
          padding: {
            left: 0;
            right: 0;
          }
        }
      }
    }
    ul {
      list-style: none;
      margin: 0;
      padding: 0;
    }
  }
}

.navbar-toggler {
  height: 3rem;
  width: 3rem;
  background: transparent url('./img/burger.svg') center center no-repeat;
  background-size: 2rem auto;
  transition: transform 0.2s, background 0.2s;
  padding: 0;
  &.open {
    transform: rotate(90deg);
  }
  &:focus {
    outline: none;
  }
}

body.scrolled {
  .nav-main {
    .navbar-brand {
      height: 2rem;
      margin: 0.5rem 0;
    }
  }
  .navbar-toggler {
    background-size: 1.7rem auto;
  }
  header {
    .navbar-nav {
      .nav-item {
        .nav-link {
          height: 3rem;
          padding: 0.5rem 1.5rem;
          line-height: 1.7rem;
        }
      }
    }
  }
  .mobilemenu {
    top: 3rem;
  }
}

.slick-arrow {
  border: 0;
  padding: 0;
  cursor: pointer;
  display: inline-block;
  box-sizing: content-box;
  width: 1.6rem;
  height: 1.6rem;

  -webkit-tap-highlight-color: rgba(255, 255, 255, 0) !important;
  -webkit-focus-ring-color: rgba(255, 255, 255, 0) !important;
  outline: none !important;

  background: {
    image: url('img/fleche1.svg');
    position: right center;
    repeat: no-repeat;
    size: 1.6rem auto;
  }
  @extend .text-hide;
  transform: scaleX(1);

  &.slick-prev {
    transform: scaleX(-1);
  }
}

.slick-dots {
  display: flex;
  height: 1.6rem;
  margin: 0;
  padding: 0 0.8rem;
  list-style: none;

  li {
    height: 1.6rem;
    padding: 0.55rem 0.2rem;

    button {
      border: none;
      background: #FFF;
      height: 0.5rem;
      width: 0.5rem;
      padding: 0;
      display: block;
      border-radius: 50%;
      cursor: pointer;
      @extend .text-hide;
    }

    &.slick-active {
      button {
        background: $primary;
      }
    }
  }
}

.pagesep {
  height: 10rem;
  text-align: center;
  background: transparent url("img/pagesep.svg") center center no-repeat;
  margin-bottom: -5rem;
  margin-top: -5rem;
  position: relative;
  z-index: 4;
  button {
    display: block;
    height: 5rem;
    width: 5rem;
    margin: 0 auto;
    border: none;
    background: none;
    position: relative;
    span {
      display: block;
      position: absolute;
      height: 5px;
      width: 5px;
      left: 2.55rem;
      bottom: 1.4rem;
      background: none;
      border: 1px solid $green;
      margin-left: -3px;
      border-radius: 50%;
      transition: all 0.2s;
      transition-timing-function: ease-in;
    }
    &.down span {
      bottom: auto;
      top: 1.4rem;
    }
    &:hover span {
      /*
      height: 19px;
      width: 19px;
      bottom: 1.02rem;
      left: 2.5rem;
      new size */
      height: 21px;
      width: 21px;
      bottom:0.9rem;
      left: 2.46em;
      margin-left: -9px;
    }
    &.down:hover span {
      /*
      height: 19px;
      width: 19px;
      top: 1.00rem;
      left: 2.5rem;
      new size:
      */
      height: 21px;
      width: 21px;
      top:0.9rem;
      left: 2.46em;
      margin-left: -9px;
    }
    &:focus {
      outline: none;
    }
  }
}

section.fh {
  min-height: calc(100vh - 3rem);
  font-size: $s16px;
  .section-title {
    font-family: $font-bold;
    color: $primary;
    font-size: 1.2em;
  }
  .section-subtitle {
    font-family: $font-bold;
    color: $primary;
  }
  h2 {
    @extend .pb-lg-5;
    @extend .pb-2;
    color: $primary;
    font-family: $font-bold;
  }
  &.dark {
    color: #FFF;
    background: $footer-bg;
    &.bg {
      background: $footer-bg url("img/bg_v_dark.svg") no-repeat right -3% top 15vh;
      background-size: auto 70vh;
    }
    .section-title, .section-subtitle, h2, a {
      color: #FFF;
    }
    h2 {
      color: $dark-title;
    }
    &.blue {
      background-color: #3582A9;

      .section-title {
        span {
          background: #3582A9;
        }
      }
    }

    .section-title {
      span {
        background: $footer-bg;
      }
    }
  }
}

.sub-fh {
  min-height: calc(100vh - 3rem);
  display: flex;
  align-content: stretch;
  & > .row {
    align-content: center;
    flex-grow: 1;
  }
}

.fh-content {
  min-height: calc(100vh - 23rem);
}

.line {
  &:after {
    display: inline-block;
    height: 0.25rem;
    width: 3rem;
    background: $primary;
    position: relative;
    content: " ";
    vertical-align: middle;
    margin-left: 1rem;
  }
}

a.cta {
  text-decoration: underline;
  padding-left: 2.4em;
  color: #285771;
  background: transparent url("img/primary_arrow.svg") left 0.39em no-repeat;
  background-size: 1.6em auto;
  font-family: $font-bold;
  display: inline-block;
  min-height: 1.6em;
  .dark & {
    background-image: url("img/white_arrow.svg");
    &.no-arrow {
      background: none;
      padding-left: 0;
    }
  }
  &.no-arrow {
    background: none;
    padding-left: 0;
  }
}

.img-fluid.fw {
  width: 100%;
}

.dark .line:after {
  background: #FFF;
}

footer {
  background: $footer-bg;
  color: #fff;

  h3 {
    font-size: 1rem;
    margin-bottom: 1rem;
    font-weight: bold;
  }

  a {
    color: $footer-link;

    &:hover {
      color: lighten($footer-link, 10%);
    }
  }

  .infos {
    > img {
      max-height: 4.5rem;
    }
    img {
      height: 100%;
      object-fit: cover;
    }
  }

  ul {
    list-style: none;
    padding: 0;
  }

  .legals {
    font-size: .85rem;
  }

  .linkedin {
    img {
      height: 2.5rem;
      width: 2.5rem;
    }
  }

  .newsletter {
    text-align: justify;

    label {
      font-weight: bold;
    }

    .singlefield {
      border-bottom: 2px solid #fff;
      display: flex;
      margin-bottom: 1rem;
    }

    input {
      width: 100%;
      background: transparent;
      color: #AADDF8;
      border: none;
    }

    button {
      color: #fff;
      font-weight: bold;
      background: transparent;
      border: none;
    }

    p {
      font-size: 0.85rem;
    }
  }
  .newsletter_subscribe_feedback {
    font-family: $font-bold;
    & > div {
      @extend .pb-3;
      display: none;
    }
    .error {
      color: #E2001A;
    }
    .sent {
      color: #93C01F;
    }
    &.error-email .error.email,
    &.error-network .error.network,
    &.sent .sent {
      display: block;
    }
  }
}

.pl-back {
  background: url("./img/goback.svg") no-repeat left center;
  background-size: 2rem;
  font-weight: bold;
  padding-left: 3rem;
  line-height: 1;
  margin-bottom: 3rem;
  display: inline-block;
}

.btn-inv {
  color: $primary;
  background: transparent;
  border: 2px solid $primary;
  &:hover {
    background: $primary;
    color: #FFF;
    .eye-icon {
      background-color: #FFF;
    }
  }

  .eye-icon { // https://codepen.io/noahblon/post/coloring-svgs-in-css-background-images
    background-color: $primary;
    -webkit-mask: url(img/eye.svg) center center no-repeat;
    mask: url(img/eye.svg) center center no-repeat;
    display: inline-block;
    width: 2em;
    margin-right: 1em;
    transition: background-color 0.2s;
  }
}

.errorpage {
  .picto {
    width: 10rem;
    height: 10rem;
    background: #EEE url('./img/404.svg') left center no-repeat;
    background-size: contain;
  }
  .bg {
    & > .container {
      display: flex;
      flex-grow: 1;
      align-content: center;
      .row {
        flex-grow: 1;
        & > div {
          display: flex;
          flex-direction: column;
          justify-content: center;
        }

      }
    }
  }
}

#rgpd {
  position: fixed;
  z-index: 4;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  background: RGBA(0, 0, 0, 0.7);
  //background: $primary;
  color: #FFF;
  padding: 0;
  .btn {
    background: #FFF;
    color: $primary;
  }
  a {
    color: #FFF;
    text-decoration: underline;
  }
  & > div {
    position: absolute;
    padding: 2rem;
    bottom: 0;
    left: 0;
    right: 0;
    //background: RGBA(0, 0, 0, 0.7);
    background: $primary;
  }

  display: none;
  &.active {
    display: block;
  }
}

body.rgpd {
  #rgpd {
    top: auto;
  }
}