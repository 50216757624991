.fh-content.legal {
  color: $text-grey;
  h1 {
    font-size: $s26px;
    font-family: $font-bold;
    color: $primary;
  }
  h2 {
    font-size: $s18px;
    font-family: $font-bold;
  }
}

.rgpd-btn {
  //border: 1px solid #000;
  border: 1px solid $primary;
  background: #FFF;
  color: $primary;
  &.active {
    background: $primary;
    color: #FFF;
    &:hover, &:focus {
      background: darken($primary, 10%);
    }
  }
  &:hover, &:focus {
    background: #DDD;
  }
}