.page-recrutement {
  //background: transparent url("../img/jobs_bg.jpg") right top no-repeat;
  .col-3.text-right {
    img {
      max-height: 3rem;
    }
  }

  .poste {
    color: $primary;
    h1 {
      @extend .pb-3;
    }
    .content {
      color: $text-grey;
    }
  }
  .no-offers {
    color: $primary;
    p:nth-child(1) {
      font-size: $s18px;
    }
    p:nth-child(2) {
      font-size: $s22px;
      font-family: $font-bold;
    }
    p:nth-child(3) {
      font-size: $s16px;
    }
    a {
      font-family: $font-bold;
    }
  }
}