.search-page {
  h1 {
    font-size: $s26px;
    font-family: $font-bold;
    color: $primary;
  }
  h2 {
    font-size: $s22px;
    font-family: $font-bold;
    color: $primary;
  }

}