.contact {
  .container-fluid.first {
    background: url("../img/pissenlit.svg") no-repeat 114.5% -4em;
    background-size: 40%;
  }

  .contact-infos {
    color: #285771;
    min-width: 200px;
    padding-top: 12px;

    img {
      height: 1.5rem;
      margin-bottom: .5rem;
    }
  }

  .intro-1 {
    font-size: 2rem;
    color: #285771;
    margin-bottom: 1em;
    font-weight: bold;
  }

  .intro-2 {
    font-size: 1.5rem;
    color: #3CAECB;
    font-weight: bold;
    margin-bottom: 2rem;
  }

  form[name="contact"] {
    max-width: 45em;

    label:not(.lblcheck) {
      color: #AEAEAE;
      transition: .3s ease-in-out;
    }

    input:not([type="checkbox"]), textarea, select {
      border: none;
      border-bottom: 2px solid #3CAECB;
      border-radius: 0;
      margin: 0 0 2rem;
      padding: .5em 0;
      width: 100%;
      transition: .5s ease-in-out;
      color: #285771;
      font-weight: bold;
      font-size: 1.25rem;
    }

    label:nth-child(n+3) {
      transform: translateY(-4rem);
    }

    input:focus ~ label, input:not(:placeholder-shown) ~ label {
      transform: translateY(-6rem);
    }

    p {
      color: #707173;
      &:last-of-type {
        margin-bottom: 3rem;
      }
    }

    .contact-text {
      position: relative;
      label {
        margin-bottom: 0;
      }
    }

    .deco {
      position: absolute;
      height: 2.5rem;
      width: 2.5rem;
      top: 2.5rem;
      left: -4.5rem;
    }

    #send {
      color: #ffffff !important;
      padding: .5em 3em .7em 1em;
      background-color: #285771;
      background-image: url("../img/send.svg");
      background-repeat: no-repeat;
      background-position: 90% center;
      background-size: 1.8rem;
      font-size: 1.25rem;
      font-weight: bold;
      line-height: 1;
      width: auto;
      border: none;
      transition: .3s ease-in-out;
    }

    #send:hover {
      background-color: #37789b;
    }
  }

}


@include media-breakpoint-up(md) {
  .contact-infos + div {
    min-height: calc(100vh - 51rem);
  }
}
@include media-breakpoint-up(lg) {
  .contact-infos {
    min-height: calc(100vh - 31rem);
  }
}