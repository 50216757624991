//fonts
@import "font.css";

// bootstrap
@import "config/bootstrapvariables";
@import "~bootstrap/scss/bootstrap";

// swiper
@import "~swiper/swiper";

// layout
@import "global";
@import "submenu";

// spécifique pages
@import "pages/home";
@import "pages/about";
@import "pages/products";
@import "pages/contact";
@import "pages/actus";
@import "pages/search";
@import "pages/qsn";
@import "pages/recrutement";
@import "pages/legal";


@import "pages/back";

// responsive
@import "responsive";