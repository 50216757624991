.backoffice {
  & > div.container {
    min-height: calc(100vh - 13rem);
  }
}

.homecarousel {
  .card {
    cursor: pointer;
    background: $dark;
    &.active {
      background: lighten($dark, 20%);
    }
  }
}