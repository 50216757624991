
.sub-menu {
  height: 0;
  position: absolute;
  overflow: hidden;
  z-index: 10;
  top: 0;
  left: 0;
  right: 0;
  transition: opacity $menutransition, height $menutransition;
  & > .container-fluid {
    margin-top: 0;
    opacity: 0;
    color: #FFF;
    transition: margin-top $menutransition, opacity $menutransition;
    .level1 {
      background: #3CAECB;
      a {
        color: #FFF;
        height: 4rem;
        /*
        line-height: 3.8rem;
        display: block;
        */
        display: flex;
        align-items: center;
        line-height: 1.2rem;
        padding-bottom: 0.3rem;
        &:hover {
          color: #FFF;
          font-family: $font-bold;
          text-decoration: none;
        }
        &.active {
          &:after {
            display: block;
            position: absolute;
            width: 12px;
            height: 12px;
            background: #FC0;
          }
        }
      }
    }
    .level2 {
      background: #3582A9;
      transition: height 0.2s;
    }
  }
  &.level1 .level2 {
    display: none;
  }
  .col-title {
    font-family: $font-bold;
    border: {
      style: solid;
      color: #FFF;
      width: 1px 0;
    }
    height: 2.5rem;
    line-height: 2.2rem;
    margin-bottom: 1rem;
    a {
      width: 100%;
      display: inline-block;
    }
  }
  .col-list {
    list-style: none;
    padding: 0;
  }

  .product-preview {
    min-height: 12rem;
    img {
      float: left;
      width: 45%;
      display: block;
      margin-top: 0.3rem;
    }
    .texte {
      display: flex;
      height: 100%;
      flex-direction: column;
      justify-content: space-between;
      margin-left: 50%;
    }
  }
  .loader {
    margin: 0 auto;
    @extend .py-5;
    text-align: center;
  }
  #lvl2-cat-tree {
    a {
      color: #FFF;
      text-decoration: none;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
    & > .col-3 {
      & > * {
        opacity: 0;
        margin-left: -10px;
        transition: opacity 0.3s, margin-left 0.3s;
      }
      &.open {
        & > * {
          opacity: 1;
          margin-left: 0;
          transition: opacity 0.3s, margin-left 0.3s;
        }
      }
    }
    li {
      transition: padding-left 0.2s;
      &.active {
        padding-left: 3rem;
        a {
          font-family: $font-bold;
          text-decoration: none;
        }
      }
      a {
        cursor: pointer;
        display: block;
        height: 2rem;
        line-height: 2rem;
        &:hover {
          font-family: $font-bold;
        }
      }
    }
  }
}

.sub-menu.open {
  height: 100vh;
  overflow: auto;
  bottom: 0;

  & > .container-fluid {
    margin-top: 5rem;
    opacity: 1;
  }
}

body.scrolled {
  header {
    &.open {
      .sub-menu {
        & > .container-fluid {
          margin-top: 3rem;
        }
      }
    }
  }
}