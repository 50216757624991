
#homeCarousel {
  position: relative;
  background: linear-gradient(36deg, #000000 30%, #011722 100%);
  .slick-arrow {
    position: absolute;
    top: calc(50% - 3.8rem);
    left: 0;
    right: auto;
    z-index: 3;
    background-position: center;
    padding: 3rem;

    &.slick-next {
      left: auto;
      right: 0;
    }
  }

  .slick-dots {
    position: absolute;
    z-index: 3;
    top: 6rem;
    left: 2rem;
    right: 0;
    justify-content: left;
    transform: translateY(-2.5rem);
    li {
      &.slick-active {
        button {
          background: #3CAECB;
        }
      }
    }
  }

  .slide {
    position: relative;

    .caption {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 100%;
      color: #FFF;
      font-size: 1.4rem;

      h1 {
        font-size: 3.5rem;
        //font-family: $font-bold;
        color: $green;
        margin-bottom: 0;
      }

      h2 {
        color: $green;
        font-size: 2.2rem;
        margin: 2rem 0;
      }
      a {
        color: $green;
        margin-top: 2rem;
      }

      .container {
        height: 100%;

        .row {
          height: 100%;

          .col-6 {
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
          }
        }
      }
      .btn {
        background: $green;
        color: #FFF;
      }
    }
    .caption-background {
      background: linear-gradient(36deg, #0c3183 30%, rgba(138, 138, 138, 0) 100%) left no-repeat;
      background-size: 60% 100%;
    }
  }

  .hero1 {
    background: url("../img/slider/slide1.jpg") no-repeat center;
  }

  .hero2 {
    background: url("../img/slider/slide2.jpg") no-repeat center;
  }

  .hero3 {
    background: url("../img/slider/slide3.jpg") no-repeat center;
  }

  .hero4 {
    background: url("../img/slider/slide4.jpg") no-repeat center;
  }

  .heroImg {
    height: calc(100vh - 10rem); //hauteur de la fenêtre du navigateur - hauteur de la barre de menu - même taille en bas
    background: {
      repeat: no-repeat;
      position: center;
      size: cover;
    }
  }

  .hero1, .hero2, .hero3, .hero4 {
    background-size: cover;
  }
}

#homeCarousel, .homecarousel {
  .slide {
    .caption {
      a {
        background-image: url("../img/oliver_queen.svg");
      }

    }
    &.s01 .caption {
      h1, h2, h3, a {
        color: #6aebf9;
      }
      a {
        background-image: url('../img/slider/arrow1.svg');
      }
    }
    &.s02 .caption {
      h1, h2, h3, a {
        color: #dfa4f4;
      }
      a {
        background-image: url('../img/slider/arrow2.svg');
      }
    }
    &.s03 .caption {
      h1, h2, h3, a {
        color: #6de6c3;
      }
      a {
        background-image: url('../img/slider/arrow3.svg');
      }
    }
    &.s04 .caption {
      h1, h2, h3, a {
        color: #d7fa54;
      }
      a {
        background-image: url('../img/slider/arrow4.svg');
      }
    }
  }
}

#specialites {
  padding: 0;
  a, h3 {
    font-family: $font-bold;
  }
  h3 a {
    color:#212529;
    text-decoration: none;
  }
  a {
    text-decoration: underline;
  }
  img {
    display: block;
    width: 100%;
  }

  .swiper-slide {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: space-between;
    padding: {
      left: $grid-gutter-width / 2;
      right: $grid-gutter-width / 2;
    }
  }

  .text {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-grow: 1;
  }
}

section.home {
  display: flex;
  flex-direction: column;
  justify-content: center;
  @extend .py-5;
}

#img-large {
  position: relative;
  div#il-bg {
    position: absolute;
    z-index: 1;
    height: 20vw;
    top: -34vw;
    left: 26vw;
    width: 15vw;
    background: transparent url("../img/bg/light.jpg") center center no-repeat;
    background-size: cover;
  }
  div#il-content {
    position: relative;
    z-index: 2;
    color: #FFF;
    font-family: $font-bold;
    font-size: 2rem;
    margin: auto;
    max-width: 800px;
    padding: 12rem 2em 0;
    /*background: transparent url("../img/pissenlit_w.svg") center top no-repeat;
    background-size: auto 10rem;*/
  }
}

#img-large-before {
  position: relative;
  z-index: 2;
  & > .container {
    min-height: calc(100vh - 12rem);
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

.respsoc {
  img {
    width: 180px;
  }
}