.product-list-page {
  background: transparent url("../img/bg_h.svg") no-repeat right -5% top -1%;
  background-size: 40% auto;
  h1, .page-desc {
    font-family: $font-bold;
    color: $primary;
  }

  .page-desc {
    font-size: $s19px;
  }
}

section.product-list {
  color: $primary;

  h2 {
    font-size: $s20px;
  }

  .product-count {

    font-family: $font-bold;
  }

  h3, h4 {
    font-size: $s16px;
    font-family: $font-bold;
    @extend .py-3;
    margin: 0;
  }

  div > a {
    font-family: $font-bold;
    text-decoration: underline;
  }

  img {
    border: 1px solid $gray-400;
  }
  .desc {
    min-height: 5em;
    display: none;
  }
}

aside {
  .cat-list {
    &, & ul {
      margin: 0;
      padding: 0;
      list-style: none;
    }

    & ul {
      padding-left: 0.5rem;
    }

    li {

      a {
        text-decoration: underline;
        display: inline-block;
        vertical-align: middle;
        font-family: $font-bold;
        color: $text-light;

        &:after {
          display: inline-block;
          content: " ";
          width: 1px;
          height: 1px;
          transition: height 0.2s, width 0.2s;
          background: $primary;
          vertical-align: middle;
          margin-left: 0.5em;
        }
        &:hover {
          &:after {
            width: 1em;
          }
        }
      }
      &.open {
        & > a {
          color: $text-dark;
          font-size: $s17px;

          &:after {
            height: 2px;
            width: 1em;
          }
        }
      }
    }
  }
  .pc-list {
    list-style: none;
    padding: 0;
    margin: 0;
    & > li {
      & > a {
        display: block;
        background: transparent url('../img/menu-produit-fleche.svg') left center no-repeat;
        padding: 0.1rem 0 0.3rem 1.5rem;
        background-size: 0.6rem auto;
      }
    }
    ul {
      list-style: none;
      padding: 0;
      margin: 0 0 0 2.5rem;
    }
    a {
      color: #3582A9;
    }
    .active {
      font-family: $font-bold;
    }
  }
}

#pro-popup {
  display: none;
  box-sizing: border-box;
  position: fixed;
  z-index: 10;
  height: 100vh;
  width: 100vw;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.35);
  * {
    margin-bottom: 2rem;
  }

  .pro-container {
    border: 2px solid lightgray;
    position: relative;
    max-width: 30em;
    padding: 3em;
    background: #fff;
    margin-bottom: 10em;

  }

  #pro-reject {
    position: absolute;
    right: 1.5rem;
    top: 1.5rem;
    cursor: pointer;

    svg {
      height: 2rem;
      width: 2rem;
    }
  }

  svg {
    height: 3em;
    width: 4em;
  }

  h2 {
    color: #00568B;
  }
  p {
    color: #9C9E9F;
  }

  button {
    font-size: 1.5em;
    padding: .25rem 1rem .5rem;
    border: none;
    color: #fff;
    background: #97BF0D;
    font-weight: bold;
    margin: 0;
    display: flex;
  }
}

.product-page.hide {
  opacity: 0;
}

.product-page {
  transition: .5s ease-in-out;

  background: transparent url("../img/bg_v.svg") no-repeat right -3% top 35vh;
  background-size: auto 50vh;

  .description {
    color: $text-grey;
    h3 {
      color: $primary;
      font-size: 1rem;
    }
  }
  &.service-page .description {
    color: $primary;
  }
  h1 {
    font-size: $s32px;
    font-family: $font-bold;
    color: $text-title;
  }
  &:not(.service-page) h2 {
    color: $primary;
    font-size: 1rem;
    font-family: $font-bold;
    //text-decoration: underline;
    padding: 1rem 0 0.5rem;
    margin: 0;
    vertical-align: middle;
    span {
      background: transparent url("../img/primary_arrow.svg") center center no-repeat;
      background-position: right -0.5rem center;
      background-size: 1.3rem auto;
      display: inline-block;
      height: 1.3rem;
      width: 1.3rem;
      content: " ";
      margin-left: 0.2rem;
      vertical-align: middle;
      transition: background-position 0.2s, transform 0.2s;
    }
    &.active span {
      transform: rotate(90deg);
      background-position: center center;
    }
  }
  &.service-page h2 {
    color: $primary;
    @extend .pt-3;
    @extend .pb-3;
    font-family: $font-bold;
  }
  h3 {
    font-size: $s18px;
    color: $primary;
    font-family: $font-bold;
  }
  .ref {
    color: $primary;
    font-size: $s16px;
  }
  .declinaisons {
    img {
      border-radius: 50%;
      height: 5rem;
      width: 5rem;
    }
  }
}
.folding {
  h2 {
    cursor: pointer;
  }
}

.product-img {
  div {
    background: #ECEBEB;
    position: relative;
  }
  img {
    display: block;
    margin: auto;
    width: 100%;
  }
  .arrownav {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: auto;
    width: 5rem;
    background: transparent url('../img/fleche3.svg') 2rem center no-repeat;
    background-size: 2.6rem auto;
    line-height: 100%;
    cursor: pointer;
    &.next {
      left: auto;
      right: 0;
    }
    &.prev {
      transform: scaleX(-1);
    }
  }
}

.product-img-thumblist {
  .arrownav {
    background: transparent url('../img/fleche3.svg') center center no-repeat;
    height: 1.5em;
    width: 1.5em;
    transform: rotate(90deg);
    margin: 0 auto;
    cursor: pointer;
    &.prev {
      transform: rotate(270deg);
    }
  }
  .thumbs {
    margin: 1em 0;
    overflow: hidden;
    .slide {
      transition: margin-top 0.3s;
    }
  }
  img {
    margin-top: 1em;
    cursor: pointer;
    opacity: 0.5;
    &:first-child {
      margin-top: 0;
    }
    &.active {
      opacity: 1;
    }
  }
}

.tab-heads {
  & > div {
    border: 1px solid $text-grey;
    color: $text-grey;
    line-height: 1rem;
    height: 3rem;
    text-align: center;
    margin-left: -1px;
    position: relative;
    z-index: 1;
    cursor: pointer;
    display: flex;
    flex: 1 1 0px;
    justify-content: center;
    flex-direction: column;
    &.active, &:hover {
      z-index: 2;
      border: 1px solid $primary;
      border-bottom-width: 2px;
      color: $primary;
    }
  }
}

.tab-container {
  .collapse {
    min-height: 10em;
  }
  .nav-tabs {
    li {
      flex: 1 1 0px;
      a {
        border: 1px solid $text-grey;
        color: $text-grey;
        line-height: 1rem;
        height: 3rem;
        text-align: center;
        margin-left: -1px;
        position: relative;
        z-index: 1;
        cursor: pointer;
        display: flex;
        justify-content: center;
        flex-direction: column;
        &.active, &:hover {
          z-index: 2;
          border: 1px solid $primary;
          border-bottom-width: 2px;
          color: $primary;
          font-family: $font-bold;
        }
      }
    }
  }

  .card {
    border: none;

  }
  .card-header {
    background: none;
    border: none;
    padding: 0;
    a {
      border: 1px solid $text-grey;
      color: $text-grey;
      font-family: $font-bold;
      font-size: $font-size-base;
      display: block;
      padding: 0.75rem 1.25rem;
      text-align: center;
      &[aria-expanded="true"] {
        color: $primary;
        border: 1px solid $primary;
        border-bottom-width: 3px;
        span {
          transform: rotate(90deg);
          background-position: center center;
        }
      }
      span {
        background: transparent url("../img/primary_arrow.svg") center center no-repeat;
        background-position: right -0.5rem center;
        background-size: 1.3rem auto;
        display: inline-block;
        height: 1.3rem;
        width: 1.3rem;
        content: " ";
        margin-left: 0.2rem;
        vertical-align: middle;
        transition: background-position 0.2s, transform 0.2s;
      }
    }
  }
}

.nav-tabs {
  display: none;
}

@include media-breakpoint-up(lg) {
  .nav-tabs {
    display: flex;
  }

  .tab-container {
    .card {
      border: none;
    }
  }

  .card .card-header {
    display: none;
  }

  .card .collapse {
    display: block;
  }
}

@include media-breakpoint-between(lg, lg) {
  .tab-content > .card.tab-pane {
    display: none;
    opacity: 0;
    &.active {
      display: block;
      opacity: 1;
    }
  }
}

@include media-breakpoint-down(lg) {
  /*
   * Changed selector to `.tab-content > .tab-pane` to override `.fade:not(.show)`
   * In BS4 Beta `.tab-pane`s were rendered hidden by just `.fade`
   */
  .tab-content > .tab-pane {
    display: block;
    opacity: 1;
  }
  .tab-container {
    .collapse {
      min-height: unset;
    }
  }

  .product-page {
    background: none;
  }
}

@include media-breakpoint-between(md, md) {
  .pdt-btns {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;
    & > div {
      flex-grow: 0.4;
      .larger {
        font-size: unset;
        padding: 0.375rem 0.75rem;
      }
    }
  }
}



.service-page {
  section.blocks {
    h3 {
      @extend .pb-5;
    }
    h4 {
      color: $primary;
      font-family: $font-bold;
      min-height: 4rem;
      line-height: 2rem;
      padding-left: 5rem;
      display: flex;
      align-items: center;
      background: {
        position: left center;
        size: 4rem;
        repeat: no-repeat;
      }
      margin-bottom: 1rem;
    }
    .block {
      @extend .pb-5;
    }
  }
}