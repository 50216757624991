$primary: #285771;
$green: #93C01F;
$footer-bg: #1C3E51;
$footer-link: #AADDF8;
$text-dark: #153342;
$text-light: #3CAECB;
$text-title: #3582A9;
$text-grey: #707173;
$text-lightgrey: #87888A;
$dark-title: #98CBDE;

$font-normal: "Variable-Bold", "Trebuchet MS", sans-serif;
$font-bold: "Variable-Black", "Trebuchet MS", sans-serif;

$s16px: 1.14285714rem;
$s17px: 1.21428571rem;
$s18px: 1.28571429rem;
$s19px: 1.35714286rem;
$s20px: 1.42857143rem;
$s22px: 1.57142857rem;
$s26px: 1.85714286rem;
$s32px: 2.28571429rem;
$s36px: 2.57142857rem;

$enable-rounded: false;